<template src="./tags.htm">
 


</template>

<script>
import { mapState } from 'vuex';
import Footer from '@/layouts/footer/Footer';
import SubHeader from '@/layouts/sub_header/Subheader.vue';
export default {
  name: "tags",
  components: {

      'footer-component': Footer,
      'sub-header': SubHeader,
  },
  data: () => ({
    valid: true,
    name: '',
    is_admin: '',
    selected: [],
    dialog: false,
    dialog2: false,
    view_table: true,
    view_info: false,
    Serial_No: '',
    overlay: true,
    editMode: false,
    ids: [],
    Serial_No_Rules: [
      v => !!v || 'Serial Number is required',
    ],      
    initForm: {
      id: '',
      serial: '',
    },
    Location_Name: '',
    Location_Name_Rules: [
      v => !!v || 'Location Name is required',
    ],
    IdentificationRules: [
      v => !!v || 'This Field is Required',
    ],
    table_header: [
      { 
        text: 'Serial Number',
        align: 'start',
        sortable: false,
        value: 'serial',
      },
      { text: '', value: 'action' },
    ],
    tags: [],
    userprofile: [
      { title: "My Profile" },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
      { title: "Logout" }
    ]
  }),
  computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
  created(){
      this.overlay = true;
  },
  watch: {
      dialog (val) {
        val || this.close()
      },
    },

  methods: {
    close()
    {
      this.dialog = false;
    },
    AddForm (){
        this.editMode = false;
      },
    edit(val) {
      this.editMode = true;
      this.dialog = true;

      this.initForm.serial = val.serial;
      this.initForm.id = val.id;
    },

    search() {
      this.overlay = true;
      this.$api
        .get('/tag/search')
          .then(res => {
              this.tags = res.data.data;
              this.overlay = false;
          })
          .catch(err => {
              this.overlay = false;
              this.$toast.open({
                  message:  err.response.data.msg,
                  type: 'error',
                  position: 'bottom'
              })
          })
    },
    clearForm()
    {
      this.$refs.form.reset();
    },
    submit() {
      if(this.$refs.form.validate()){
       this.$api
         .post('/tag', this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Submitted!',
                    type: 'success',
                    position: 'bottom'
                })
                this.close();
                this.clearForm();
                this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            })
      }
    },
    delete_selected (){
        var result = confirm("Are you sure you want to delete?");
        if (result==true) {
          this.ids = this.selected.map(tags => {
            return tags.id;
          });

          this.$api
            .post('/tag/delete', {ids: this.ids})
            .then(res => {
                this.$toast.open({
                    message:  res.data.msg,
                    type: 'success',
                    position: 'bottom'

                }),
                this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg,
                    type: 'error',
                    position: 'bottom'
                })
            })
        }
      },
    closeDialog () {
          this.$refs.form.reset(),
          this.dialog=!this.dialog
      },
    save(){
      if(this.$refs.form.validate()){
      
      this.$api
         .put(`/tag/${this.initForm.id}`, this.initForm)
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Saved!',
                    type: 'success',
                    position: 'bottom'
                })
                this.close();
                this.clearForm();
                this.search();
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            })
          }
      },
    },
  mounted() {
    this.search();

    this.name = this.user.name;
    this.is_admin = this.user.is_admin;
    if (!this.isAuthenticated) {
      this.$router.replace({ name: 'auth.login' })
    }
  }
};
</script>